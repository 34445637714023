<template>
  <div id="outNetwork" class="content">
    <div class="box-inpage out-work">
      <div class="container">
        <el-row :gutter="30">
          <el-col :span="24" :md="10" class="set-center-tb">
            <p class="head-name-page">
              Our Network
              <br />and Partners
            </p>
            <span class="sub-name-page">
              Suandok Medical Innovation District
            </span>
            <!-- <p class="small-text">
              SMID ย่านนวัตกรรมการแพทย์สวนดอก งานบริการและงานวิจัย
              ที่ดีที่สุดในภาคเหนือ บริการค้นหาโรงพยาบาล และคลินิกในย่าน
            </p> -->
            <p class="sub-page">
              <!-- SMID ย่านนวัตกรรมการแพทย์สวนดอก หรือ Suandok Medical Innovation
              District (SMID) งานบริการและงานวิจัย ที่ดีที่สุดในภาคเหนือ
              บริการค้นหาโรงพยาบาล และคลินิกในย่าน -->
              ย่านนวัตกรรมการแพทย์สวนดอก (Suandok Medical Innovation
              District)<br />
              มุ่งสู่การเป็นศูนย์กลางแห่งนวัตกรรมการแพทย์ของภูมิภาคอย่างยั่งยืน
            </p>
          </el-col>
        </el-row>
      </div>
      <groupSuandok />
    </div>
    <div class="container detill-content">
      <div class="name-head text-center">Partners we work with</div>
      <p class="set-text">
        ย่านนวัตกรรมการแพทย์สวนดอก มีการเชื่อมโยงและสนับสนุนจากหน่วยงานต่างๆ
        ทั้งจากภายในและภายนอกมหาวิทยาลัยเขียงใหม่ อาทิ คณะต่างๆ
        ที่เกี่ยวข้องกับการพัฒนานวัตกรรมทางการแพทย์ ภายใต้มหาวิทยาลัยเชียงใหม่
        อุทยานวิทยาศาสตร์ภาคเหนือ หน่วยงานภายใต้กระทรวงสาธารณสุขและกรมการแพทย์
        สำนักงานนวัตกรรมแห่งชาติ
        <span class="space-hide">asdddd</span> โรงพยาบาลและคลินิกเอกชน
        กรมส่งเสริมอุตสาหกรรม เป็นต้น
      </p>
      <el-row :gutter="20" class="scroll-auto">
        <vue-flex-waterfall
          class="box-card-custom"
          :col="2"
          :col-spacing="15"
          :break-by-container="false"
        >
          <cardLong
            :address="data_address"
            @sendOpenModal="addOpenModal"
            @getServiceById="setServiceId"
            v-for="(data_address, index) in serviceAll"
            :key="index"
          />
        </vue-flex-waterfall>
      </el-row>
    </div>
    <modalAnother
      :dataOpen="getModal"
      @dataClose="closeModal"
      :serviceId="serviceId"
    />
  </div>
</template>
<style lang="scss" scope>
.sub-page {
  font-size: 25px;
  font-weight: 500;
  color: #625b67;
  width: 100%;
  padding-top: 30px;
  word-break: break-word;
}
</style>
<script>
import VueFlexWaterfall from "vue-flex-waterfall";
import groupSuandok from "@/components/groupSuandok";
import cardLong from "@/components/cardLong";
import modalAnother from "@/components/modalAnother";
import { HTTP } from "@/service/axios.js";
export default {
  data() {
    return {
      getModal: false,
      serviceAll: [],
      serviceId: "",
    };
  },
  components: {
    cardLong,
    modalAnother,
    groupSuandok,
    VueFlexWaterfall,
  },
  mounted() {
    this.getserviceAll();
  },
  methods: {
    addOpenModal(data) {
      this.getModal = data;
    },
    closeModal(data) {
      this.getModal = data;
    },
    getserviceAll() {
      HTTP.get(`services/0/100/all/0`)
        .then((res) => {
          console.log("getserviceAll", res);
          if (res.data.success) {
            this.serviceAll = res.data.obj;
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("login error", e);
        });
    },
    setServiceId(id) {
      this.serviceId = id;
    },
  },
};
</script>
<style scoped>
.space-hide {
  color: white;
}
@media (max-width: 1199.98px) {
  .space-hide {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .vue-flex-waterfall {
    align-content: initial;
  }
}
</style>